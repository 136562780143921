/* eslint-disable react/prop-types */
// TODO : figure out propType validation for these

import React, { Component } from "react";
import Div100vh from "react-div-100vh";
import { Link } from "gatsby";
import { AppContext } from "~context/AppContext";
import Layout from "~components/Layout";

class NotFoundPageComponent extends Component {
  state = {
    mounted: false
  };

  componentDidMount() {
    this.props.appContext.setIconColor(`white`);

    this.setState({
      mounted: true
    });
  }

  //

  render() {
    return (
      <>
        <Layout
          className={`page-not-found mount-loader relative ${
            this.state.mounted ? `mounted` : ``
          }`}
        >
          <Div100vh className="relative">
            <div className="w-screen h-screen grid bg-sisal text-white">
              <section className="results-page__inner grid-end--18 grid-end--sm-16 grid-start--12 grid-start--sm-3 relative flex items-center justify-center flex-col">
                <h2 className="mount-loadable mount-loadable--100 f1 text-center">
                  404
                </h2>

                <p className="results-page__overlay__text b2 text-center">
                  Wrong order. We&apos;re sorting it out with our kitchen now.
                </p>

                <Link
                  to="/"
                  className="results-page__inner__button button button--black w-full relative block"
                >
                  BACK TO HOME
                </Link>
              </section>
            </div>
          </Div100vh>
        </Layout>
      </>
    );
  }
}

const NotFoundPage = () => (
  <AppContext.Consumer>
    {appContext => <NotFoundPageComponent appContext={appContext} />}
  </AppContext.Consumer>
);

export default NotFoundPage;
